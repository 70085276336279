import React from 'react';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { PageProps } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/controls/SEO';

type NotFoundPageProps = PageProps;

const NotFoundPage = ({ location }: NotFoundPageProps) => (
  <Layout location={location}>
    <SEO
      key="page-seo-item"
      title="Not Found"
      description="Not Found Page"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />

    <div className="container neo-text-content">
      <h1>Not Found</h1>
      <p>The page you have requested doesn't exist.</p>
    </div>
  </Layout>
);

export default withPrismicUnpublishedPreview(NotFoundPage);
